@import "./reset.scss";

$primay-font: #333;
$color-disabled: rgba(128, 128, 128, 0.418);
$color-inactive: #ae00ff;
$color-active: #adff2f;
$btn-primary: #1f61dc;
$btn-secondary: pink;
$btn-background: rgb(160, 160, 160);
$page-background: #fff;

$size-desktop: 1919px;
$size-tablet-landscape: 768px;
$size-tablet: 480px;
$size-phone: 480px;

body {
  font-family: Lato, sans-serif;
  font-size: 13px;
  background-color: $page-background;
  color: $primay-font;
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
table {
  border-collapse: collapse;
}

.nav-container {
  display: flex;
  align-items: center;
  min-height: 70px;
  border-bottom: 1px solid #dfdfde;
  min-height: 60px;
  padding-bottom: 15px;
  padding: 0 2rem;

  .tool-heading {
    font-size: 20px;
    color: #ff6600;
    margin-right: 35px;
    text-transform: uppercase;
    font-weight: 900;
  }

  .nav-left {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    margin-right: auto;
  }
  .nav-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .nav-item {
    color: #414147;
    font-size: 12px;
    margin-right: 35px;
    padding-top: 7px;
    font-size: 14px;
    text-align: center;
    line-height: 17px;
    a {
      color: #414147;
      text-decoration: none;
    }
    a.nav-active {
      color: black;
      border-bottom: 2px solid #f47e42;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.form-flex-row {
  display: flex;
  align-items: baseline;
  label {
    white-space: pre;
  }
}

.main-content {
  padding: 24px;
}
.page-nav {
  display: flex;
  min-height: 36px;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(217, 217, 217);
  h1 {
    color: rgb(35, 39, 41);
    font-family: inherit;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.15px;
    line-height: 32px;
    margin: 0px;
    margin-right: 24px;
    white-space: nowrap;
  }
  .page-nav-tabs {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    flex-grow: 1;
    overflow: hidden;
    align-items: baseline;

    nav {
      display: flex;
      overflow: hidden;
      width: 100%;

      .page-nav-item {
        -webkit-box-align: center;
        align-items: center;
        border-bottom: 2px solid transparent;
        display: inline-flex;
        height: 26px;
        -webkit-box-pack: center;
        justify-content: center;
        border-bottom-color: rgb(255, 81, 0);
      }
    }
    .page-nav-actions {
      flex: 0 0 auto;
      margin-left: auto;
      padding-left: 16px;
      .page-nav-actions-container {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        .primary-btn {
          display: flex;
          flex-direction: row;
          font-size: 14px;
          letter-spacing: 0.15px;
          line-height: 20px;
          background: rgb(255, 81, 0);
          color: white;
          align-items: center;
          border-radius: 4px;
          border: 0px;
          box-sizing: border-box;
          cursor: pointer;
          display: inline-flex;
          flex-direction: row;
          flex-shrink: 0;
          font-family: inherit;
          font-weight: 600;
          height: 36px;
          -webkit-box-pack: center;
          justify-content: center;
          margin: 0px;
          overflow: hidden;
          padding: 0px 6px;
          position: relative;
          pointer-events: inherit;
          text-overflow: ellipsis;
          user-select: inherit;
          vertical-align: middle;
          white-space: nowrap;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.field-array-group {
  margin-bottom: 1rem;
}
.cmd-btn {
  margin: 0.5rem 0rem;
}
.fa-delete {
  padding-left: 0.5rem;
  cursor: pointer;
  font-size: 0.85rem;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.item-list {
  font-size: 12px;
  padding: 0;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-right: 0;
  border-left: 0;
  color: #343739;
  thead {
    background-color: #f5f5f5;
    tr {
      th {
        color: #444749;
        padding: 0 10px;
        height: 40px;
        border-left: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        border-bottom: 0;
        border-right: 0;
        font-weight: bold;
        vertical-align: middle;
        text-transform: none;
        text-decoration: none;
      }
    }
  }
  tbody {
    background-color: #ffffff;
    tr {
      td {
        border-left: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        border-bottom: 0;
        border-right: 0;
        padding: 6px;
        text-align: left;
        font-weight: normal;
        vertical-align: middle;
      }
    }
  }
}
.primary-table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;

  th {
    text-align: left;
    padding-left: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 0.85rem;
    span {
      color: #2e3849;
    }
  }
  tbody {
    tr {
      border-top: 4px solid #f5f6f8;
      td {
        vertical-align: middle;
        text-align: left;
        padding-left: 24px;
        padding-top: 8px;
        padding-bottom: 8px;
        span {
          color: #2e3849;
        }
        .table-row-subtext {
          font-size: 1rem;
          color: #5a6881;
        }
      }
      .primary-table-link {
        // hack for semi bold
        text-shadow: 0 0 0 $primay-font;
        text-decoration: underline;
      }
      .primary-table-link-no-underline {
        // hack for semi bold
        text-shadow: 0 0 0 $primay-font;
      }
    }
  }
}
.pagination-row {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
  span {
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  span.pg-disabled {
    cursor: default;
    color: $color-disabled;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
.bubble-span {
  background-color: #eaecf1;
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 0.75rem;
  color: $primay-font;
}

.container-flex {
  display: flex;
  flex-direction: row;
}

.commodities_produced {
  display: flex;
}
.double-val-label {
  font-family: monospace;
  margin: 0 0.2rem;
  // border: 1px dashed rgb(196, 196, 196);
}
.double-val-label > span {
  // background-color: #656565;
  color: $primay-font;
  display: table-cell;
  padding: 0.25rem;
  font-size: 0.75rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.double-val-label > span.primary {
  // background-color: #337ab7;
}

.symbol-Au {
  color: rgb(235, 235, 235) !important;
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
}
.symbol-Ag {
  background: linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);
}
.symbol-Cu {
  background: #da8a67;
  background: linear-gradient(to bottom, #da8a67 0%, #cb7b58 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #da8a67), color-stop(100%, #cb7b58));
  background: -webkit-linear-gradient(top, #da8a67 0%, #cb7b58 100%);
  background: -moz-linear-gradient(top, #da8a67 0%, #cb7b58 100%);
  background: -o-linear-gradient(top, #da8a67 0%, #cb7b58 100%);
  background: -ms-linear-gradient(top, #da8a67 0%, #cb7b58 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#DA8A67', endColorstr='#CB7B58', GradientType=0);
  -webkit-box-shadow: inset 0 1px 0 #e99976;
  -moz-box-shadow: inset 0 1px 0 #e99976;
}

.upper {
  text-transform: uppercase;
}

.no_wrap {
  white-space: nowrap;
}

.main {
  display: flex;
  flex-direction: row;
  .content {
    height: calc(100vh - 73px);
    flex-grow: 1;
    padding: 24px;
  }
  .sidebar {
    width: 30%;
    height: calc(100vh - 73px);
    // height: ;
  }
}

.document_viwer {
  display: flex;
  .tight-list {
    padding: 0;
    font-size: 0.8rem;
    list-style: none;
    margin-right: 2rem;
    li {
      cursor: pointer;
      display: flex;
      padding: 4px;
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: #ffffff;
      border-left: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      justify-content: space-between;
      &:first-of-type {
        border-top: 1px solid #e0e0e0;
      }
      span {
        padding: 0 0.5rem;
      }
      &:hover {
        background-color: whitesmoke;
        transition: 200ms;
      }
    }
  }
  .doc_panel {
    flex-grow: 1;
  }
}
.item_id {
  color: rgb(92, 90, 90, 0.5);
}
th {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

table.edit {
  padding: 0;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-right: 0;
  border-left: 0;
  color: #343739;
  font-size: 0.85rem;

  th {
    width: 20%;
    min-width: 20%;
    line-height: 130%;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    padding: 8px;
  }
  td {
    width: 30%;
    min-width: 30%;
    line-height: 130%;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    padding: 8px;
  }
  [type="text"] {
    width: 100px;
  }
}
.full-content > input {
  max-width: 100%;
  width: 100%;
}

table.inner-table {
  padding: 0;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-right: 0;
  border-left: 0;
  color: #343739;
  font-size: 0.85rem;
  th {
    width: 20%;
    min-width: 20%;
    line-height: 130%;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    // padding: 4px;
    background-color: #ececec;
  }
  td {
    width: 30%;
    min-width: 30%;
    line-height: 130%;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    // padding: 4px;
  }
  input {
    max-width: 80px;
  }
}

[type="text"],
[type="password"],
[type="email"],
[type="tel"],
[type="search"],
[type="date"],
select {
  height: 30px;
  border-radius: 2px;
  border: 1px solid #c3c2c2;
  padding: 0 5px;
  outline: none;
}

.react-select-container {
  .react-select__control {
    border: 1px solid #c3c2c2;
    border-radius: 2px;
    min-height: 30px;
    outline: none;
    .react-select__value-container {
      padding: 0 5px;
      .react-select__multi-value {
        margin: 0 2px;
      }
    }
    .react-select__input-container {
      margin: 0;
      padding: 0;
    }
  }
  .react-select__indicator {
    padding: 0px;
  }
}
